class PhoneMask {
    constructor(selector) {
        this.init(selector);
    }
    
    init(el) {
        if (!window.maskTel_k) {
            window.maskTel_k = 10;
        }
        window.maskTel_k--;
        if (window.maskTel_k <= 0) return;
        if (typeof $.masksLoad == "undefined") {
            setTimeout(function () {
                maskTel(el);
            }, 200);
        } else {
            window.maskTel_k = 10;
            var maskList = $.masksSort(phoneCode, ["#"], /[0-9]|#/, "mask");
            var maskOpts = {
                inputmask: {
                    definitions: {
                        "#": { validator: "[0-9]", cardinality: 1 },
                    },
                    clearIncomplete: false,
                    showMaskOnHover: false,
                    autoUnmask: false,
                    oncomplete: function () {
                        $(this)
                            .parents("form")
                            .find('input[name="name"]')
                            .focus();
                    },
                },
                match: /[0-9]/,
                replace: "#",
                list: maskList,
                listKey: "mask",
            };
            $(el).inputmasks(maskOpts);
        }
    }
}

export default PhoneMask;